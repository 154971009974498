import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/navigate.actions';
import { PAGES } from '../constants/pages.constants';
import { NavigationPage } from '../models/navigate.model';

export const initialState: NavigationPage = {
  name: 'Filtro',
  component: PAGES.FILTRO,
  action: actions.filtroPage
};

const _navigateReducer = createReducer(
  initialState,
  on(actions.filtroPage, (state, { filtro }) => ({
    ...state,
    name: 'Filtro',
    component: PAGES.FILTRO,
    action: actions.filtroPage,
    filtro: filtro
  })),
  on(actions.bandejaDeudaPage, (state, { deudaSeleccionada, filtro }) => ({
    ...state,
    name: 'Bandeja Deuda',
    component: PAGES.BANDEJA_DEUDA,
    action: actions.bandejaDeudaPage,
    deudaSeleccionada: deudaSeleccionada,
    filtro: filtro
  }))
);

export function navigateReducer(state: any, action: any) {
  return _navigateReducer(state, action);
}
