import { DeudaBandejaComponent } from '../../pages/deuda-bandeja/deuda-bandeja.component';
import { DeudaFiltroComponent } from 'src/app/pages/deuda-filtro/deuda-filtro.component';
import { DeudaPagoFormComponent } from 'src/app/pages/deuda-pago-form/deuda-pago-form.component';
import { DeudaDetallePagoComponent } from 'src/app/pages/deuda-detalle-pago/deuda-detalle-pago.component';

export const PAGES: Record<string, any> = {
  FILTRO: DeudaFiltroComponent,
  BANDEJA_DEUDA: DeudaBandejaComponent,
  FORM_PAGO_DEUDA: DeudaPagoFormComponent,
  DETALLE_PAGO: DeudaDetallePagoComponent
};
