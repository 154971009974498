import { createAction, props } from '@ngrx/store';
import { IFiltros } from '../../models/filtros.model';
import { IDeuda } from '../../models/deuda.model';

export const filtroPage = createAction('[PAGE] Filtro', props<{ filtro: IFiltros }>());
export const bandejaDeudaPage = createAction(
  '[PAGE] Bandeja Deuda',
  props<{ deudaSeleccionada: IDeuda[]; filtro: IFiltros }>()
);
export const formPagoPage = createAction('[PAGE] Form Pago Deuda', props<{ deudaSeleccionada: IDeuda[] }>());
export const detallePagoPage = createAction('[PAGE] Detalle Pago');
