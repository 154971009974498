import { Component, OnDestroy, OnInit } from '@angular/core';
import { IFiltros } from 'src/app/models/filtros.model';
import { CommonModule } from '@angular/common';
import { finalize, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/models/app.reducers';
import { PAGES } from 'src/app/store/constants/pages.constants';

@Component({
  selector: 'ma-deuda-bandeja',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './deuda-bandeja.component.html',
  styleUrl: './deuda-bandeja.component.scss'
})
export class DeudaBandejaComponent implements OnInit, OnDestroy {
  filtros!: IFiltros;
  textoFiltro: string = '';
  cargando: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.store.subscribe((state: any) => {
      if (state.navigate.component === PAGES.BANDEJA_DEUDA) {
        this.filtros = { ...state.navigate.filtro };
        this.textoFiltro = this.filtros.textoFiltro;
        this.buscar();
      }
    });
  }

  buscar() {
    //this.cargando = true;
    //TODO llama al servicio de consulta de deuda
    console.log('buscar');
  }

  irDetalle() {
    console.log('irDetalle');
  }
}
