import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuButtonModule, AuRippleModule, AuSizeModule } from '@mandina/andina-ui';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IFiltros } from 'src/app/models/filtros.model';
import { bandejaDeudaPage } from 'src/app/store/actions/navigate.actions';
import { AppState } from 'src/app/store/models/app.reducers';
import { PAGES } from 'src/app/store/constants/pages.constants';
import { IDeuda } from 'src/app/models/deuda.model';

@Component({
  selector: 'ma-deuda-detalle-pago',
  standalone: true,
  imports: [CommonModule, AuButtonModule, AuSizeModule, AuRippleModule],
  templateUrl: './deuda-detalle-pago.component.html',
  styleUrl: './deuda-detalle-pago.component.scss'
})
export class DeudaDetallePagoComponent {
  filtro: IFiltros = { textoFiltro: '' };
  subscription: Subscription = new Subscription();
  deudaSeleccionada: IDeuda[] = [];
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.cargarPagina();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  cargarPagina() {
    this.subscription = this.store.subscribe((state: any) => {
      if (state.navigate.component === PAGES.BANDEJA_DEUDA) {
        this.deudaSeleccionada = { ...state.navigate.deudaSeleccionada };
        this.filtro = { ...state.navigate.filtro };
        this.obtenerDetalleDeuda();
      }
    });
  }

  volver() {
    this.store.dispatch(bandejaDeudaPage({ deudaSeleccionada: null, filtro: this.filtro }));
  }

  obtenerDetalleDeuda() {}
}
