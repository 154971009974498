import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorHttpService implements HttpInterceptor {
  constructor() {
    console.log('aaaaa');
    //Creo una copia del metodo a interceptar
    const origOpen = XMLHttpRequest.prototype.open;

    //Override del metodo open
    XMLHttpRequest.prototype.open = function (method, url) {
      //Llamo al metodo original, necesito primero llamar al open y luego añadir el header
      origOpen.apply(this, arguments);

      this.setRequestHeader('Ocp-Apim-Subscription-Key', environment.apimSubsKey);
    };
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }
}
