// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apimSubsKey: 'd61b8fb29156415688b3099cc3fd51d0',
  apiUrls: {
    API_BASE: 'https://administracion.devmercantilandina.com.ar'
  },
  recaptcha: {
    //siteKey: '6LesMiAqAAAAAH2fsHdKXBPENpBtk6Scn1ynE7I6'
    siteKey: '6LdNNRMqAAAAAMk-znhIC8GO56qbaLQO4qamdIDL'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
