import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AuButtonModule,
  AuCardModule,
  AuRippleModule,
  AuSizeModule,
  AuTextfieldDirectiveModule,
  AuTextfieldModule
} from '@mandina/andina-ui';
import { finalize, Subscription } from 'rxjs';
import { IFiltros } from 'src/app/models/filtros.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/models/app.reducers';
import { PAGES } from 'src/app/store/constants/pages.constants';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { bandejaDeudaPage } from 'src/app/store/actions/navigate.actions';
import { SoloNumerosEnterosDirective } from 'src/app/directives/solo-numeros-enteros.directive';

@Component({
  selector: 'ma-deuda-filtro',
  standalone: true,
  imports: [
    CommonModule,
    AuTextfieldModule,
    AuTextfieldDirectiveModule,
    AuSizeModule,
    AuButtonModule,
    AuRippleModule,
    AuCardModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    SoloNumerosEnterosDirective
  ],
  templateUrl: './deuda-filtro.component.html',
  styleUrl: './deuda-filtro.component.scss'
})
export class DeudaFiltroComponent implements OnInit, OnDestroy {
  filtros!: IFiltros;
  filtroForm!: FormGroup;
  textoFiltro: string = '';
  tokenRecaptcha: string | null = null;
  cargando: boolean = false;
  formatoValido: RegExp = /^[0-9\s\-_\.\/]*$/;
  mensaje: string = '';
  estiloMensaje: string = 'active';
  formInvalido: boolean = true;
  subscription: Subscription = new Subscription();
  private singleExecutionSubscription: Subscription = new Subscription();

  constructor(private store: Store<AppState>, private recaptchaV3Service: ReCaptchaV3Service) {
    this.filtroForm = new FormGroup({
      textoFiltro: new FormControl('', [Validators.required, Validators.minLength(7)])
    });
    /*this.subscription = this.store.subscribe((state: any) => {
      if (state.navigate.component === PAGES.FILTRO) {
        this.filtros = { ...state.navigate.filtro };
        this.filtroForm?.controls['textoFiltro'].setValue(this.filtros.textoFiltro);
        this.textoFiltro = this.filtros.textoFiltro;
      }
    });
setTimeout(() => {
  this.cargando = true;
}, 1300);*/
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.singleExecutionSubscription.unsubscribe();
  }

  ngOnInit(): void {}

  continuar() {
    this.cargando = true;
    this.singleExecutionSubscription = this.recaptchaV3Service
      .execute('buscar')
      .pipe(
        finalize(() => {
          this.cargando = false;
        })
      )
      .subscribe({
        next: (token: string | null) => {
          this.tokenRecaptcha = token;
          //TODO: Ver en donde se guarda el toekn y como se pasa
          this.filtros = { textoFiltro: this.filtroForm.controls['textoFiltro'].value };
          this.store.dispatch(bandejaDeudaPage({ deudaSeleccionada: null, filtro: this.filtros }));
          console.log(token);
        },
        error: (error) => {
          console.log(error);
        }
      });
  }

  blanquearErroresValidacion() {
    this.setearErroresValidacion('', 'active');
  }

  /**
   * Setea las variables usadas para mostrar los errores del input y errores del form para
   * deshabiltiar botón de búsqueda
   * @param mensaje
   * @param estilo
   */
  setearErroresValidacion(mensaje: string, estilo: string) {
    this.mensaje = mensaje;
    this.estiloMensaje = estilo;
    if (this.mensaje) {
      this.filtroForm.controls['textoFiltro'].setErrors({ invalido: true });
      this.formInvalido = true;
    } else {
      this.filtroForm.controls['textoFiltro'].setErrors(null);
      this.formInvalido = false;
    }
  }

  validarLongitud() {
    if (!this.textoFiltro) return;
    if (this.textoFiltro.toString().length < 7) {
      this.setearErroresValidacion('Ingresá al menos 7 números', 'error');
    }
  }

  validarCaracteres() {
    if (
      this.textoFiltro.toString().includes('.') ||
      this.textoFiltro.toString().includes('-') ||
      !this.formatoValido.test(this.textoFiltro)
    ) {
      this.setearErroresValidacion('Ingresá sólo números (sin guiones ni puntos)', 'error');
    }
  }

  validar() {
    //console.log(event);
    //if (event['keyCode'] === 110 || event['keyCode'] === 109) event.preventDefault(); //omito -
    this.blanquearErroresValidacion();
    this.textoFiltro = this.filtroForm.controls['textoFiltro'].value;
    this.validarLongitud();
    this.validarCaracteres();
  }

  filtrar() {
    console.log('filtrar');
    this.textoFiltro = this.filtroForm.controls['textoFiltro'].value;
    if (!this.textoFiltro) return;
    this.blanquearErroresValidacion();
    this.validarCaracteres();
    this.validarLongitud();
  }
}
