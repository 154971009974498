import { Component } from '@angular/core';

@Component({
  selector: 'ma-deuda-pago-form',
  standalone: true,
  imports: [],
  templateUrl: './deuda-pago-form.component.html',
  styleUrl: './deuda-pago-form.component.scss'
})
export class DeudaPagoFormComponent {

}
