<section class="seccion-abajo">
  <au-card borderType="shadow" class="card-filtro">
    <div class="contenedor-card">
      <img alt="logo" src="assets/images/MercantilAndinaLogo.png" />
    </div>
    <div>
      <p class="titulo-card au-text--medium au-text--title-sm au-neutral-900">Pagá tus pólizas online</p>
      <p class="subtitulo-card au-text--regular au-text--body-md au-neutral-700">Accede a tus vencimientos ingresando tu<strong> DNI, CUIL, CUIT ó N° de póliza.</strong></p>
      <!--span class="subtitulo-card au-text--regular au-text--body-md">Accede a tus vencimientos ingresando tu </span>
      <span class="subtitulo-card au-text--medium au-text--body-md"> DNI, CUIL, CUIT ó N° de póliza.</span-->
    </div>
    <div class="contenedor-card">
      <form [formGroup]="filtroForm">
        <au-textfield [infoMessage]="mensaje" [severity]="estiloMensaje">
          <span class="c-float-label">
            <input
              auTextfield
              size="sm"
              type="number"
              id="filtro-input-dni-cuit-poliza"
              formControlName="textoFiltro"
              [value]="textoFiltro"
              autocomplete="off"
              (keydown)="validar()"
              (blur)="filtrar()"
            />
            <label>DNI, CUIL, CUIT ó N° de póliza</label>
          </span>
        </au-textfield>
      </form>
    </div>

    <div class="contenedor-card">
      <au-button
        auRipple
        [fullWidth]="true"
        size="lg"
        id="filtro-boton-continuar"
        [disabled]="formInvalido"
        (click)="continuar()"
        >Continuar</au-button
      >
    </div>
  </au-card>
</section>
