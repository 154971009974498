<section class="seccion-arriba">
  <img src="assets/images/marca-de-agua.svg" class="marca-de-agua" alt="Mercantil Andina" />
  <div>
    <h2 class="titulo">
      <div *ngIf="titulo">
        <span>{{ titulo }}</span>
      </div>
    </h2>
  </div>
</section>
<div class="contenedor">
  <ng-container *ngComponentOutlet="componente"></ng-container>
</div>
