import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from './store/models/app.reducers';
import { PAGES } from './store/models/pages';
import { FILTRO } from './constants/filtro.contants';
import { filtroPage } from './store/actions/navigate.actions';

@Component({
  selector: 'ma-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web-cobranza-inmediata';
  subscription: Subscription = new Subscription();
  componente: any = null;
  titulo: string = '';

  constructor(private store: Store<AppState>) {
    this.subscription.add(
      this.store.subscribe((page) => {
        this.componente = PAGES[page.navigate.component];
        window.scroll({ top: 0, behavior: 'auto' });
      })
    );
    this.store.dispatch(filtroPage({ filtro: FILTRO }));
  }
}
